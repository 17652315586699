
                    @import "@/base/global-css/_sass-includes.scss";
                    @import "./src/main/webapp/static/themes/base/public/js/overrides.scss";
                













































































































































































































































































.SubscribeAd {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	color: $color-white;
	background-color: $color-neutral-5;
	box-shadow: -2px -2px 4px rgba(32, 32, 32, 0.25);
	transition: all 0.3s ease;
	transform: translate(0, 100%);
	@include breakpoint(medium) {
		left: auto;
		transform: translate(100%, 0);
	}
	opacity: 0;
	&.active {
		transform: translate(0, 0);
		opacity: 1;
	}
	&.reverse {
		color: $color-text;
	}
	&-minimized {
		@include text(2);
		@include inset-squish-md;
		@include colorReverse;
		color: $color-white;
		background-color: $color-primary-5;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $space-md;
	}
	&-minimzedText {
		display: flex;
		gap: $space-sm $space-md;
		flex-wrap: wrap;
	}
	&-open {
		min-height: 40vh;
		max-height: 50vh;
		overflow-y: auto;
		@include inset-lg;
		background-position: bottom right;
		background-repeat: no-repeat;
		background-size: 170%;
		@media (min-width: 450px) {
			background-size: 125%;
			min-height: 250px;
		}
		@include breakpoint(medium) {
			background-size: 105%;
			min-height: 300px;
		}
		display: flex;
		flex-direction: column;
		gap: $space-md;
	}
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $space-lg;
	}
	&-credit {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		color: $color-white;
		background-color: rgba(0, 0, 0, 0.5);
		@include text(1);
		@include inset-squish-sm;
		text-align: right;
	}
	&-text--italic {
		font-style: italic;
	}
	&-text--large {
		@include text(3);
		margin: 0;
		max-width: 50%;
		@include breakpoint(medium) {
			@include text(3);
			max-width: 75%;
		}
		@include breakpoint(large) {
			max-width: 80%;
		}
	}
}
.Dialog--ad {
  .Dialog-container {
    outline: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    position: relative;
    max-width: calc(100vh - 0.5rem);
    max-height: calc(100vw - 0.5rem);
    @include breakpoint(xlarge) {
      max-width: calc(100vh - 3rem);
      max-height: calc(100vw - 3rem);
    }
  }
  .Dialog-container-close {
	top: -$space-md;
	@include breakpoint(medium) {
		right: $space-sm;
	}
    .Icon {
      fill: $color-white;
    }
  }
  .Dialog-container-body {
    padding: 2rem 1rem;
	@include breakpoint(medium) {
	  padding: 2rem;
	}
    overflow-y: visible;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }
  }
}
.FullscreenAd-minimized {
  z-index: 1001;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  @include inset-md;
  @include text(3);
  @include colorReverse;
  color: $color-white;
  background-color: $color-primary-5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-md;
}
.FullscreenAd-minimized-text {
  display: flex;
  gap: $space-sm $space-md;
  flex-wrap: wrap;
}
